import React, {useEffect, useState} from "react";
import FarmerContainer from "../FarmerContainer";
import TiersTab from "../TiersTabs";
import {MintChoiceDesktop} from "./MinterChoose";
import {ConnectWalletSimple} from "../NavBar";
import {Slide} from "../Slide";
import GlassCard from "../TiersCards";
import tiers1Image from "../../../assets/images/bigsmile.jpeg";
import tiers2Image from "../../../assets/images/farmer.png";

const map1 = new Map();
map1.set(1, <GlassCard title="Silver pick" cardImage={tiers1Image} />);
map1.set(2, <GlassCard title="Gold pick" cardImage={tiers2Image} />);

export const MinterDesktop = ({
                           address,
                           setAddress,
                           isWhitelist,
                           mintNumber,
                           setMintNumber,
                           add,
                           minus,
                       }) => {
    const [glassCardElement, setGlassCardElement] = useState(map1.get(1));
    useEffect(() => {
        if (mintNumber < 5) {
            setGlassCardElement(map1.get(1));
        }
        if (mintNumber >= 5) setGlassCardElement(map1.get(2));
    }, [mintNumber]);
    if (process.env.PAUSED === "false")
        return (
            <div className="md:grid mt-0 md:mt-16  md:grid-cols-2  md:place-items-center  gap-20  flex flex-row ">
                <div className="md:place-self-end">
                    <FarmerContainer>
                        <TiersTab
                            tabToSelect={glassCardElement}
                            setChangeTab={setGlassCardElement}
                            setMint={setMintNumber}
                            elements={map1}
                            mint={mintNumber}
                        />
                        {address && (
                            <MintChoiceDesktop
                                setMintNumber={setMintNumber}
                                mintNumber={mintNumber}
                                isWhitelist={isWhitelist}
                                add={add}
                                minus={minus}
                            />
                        )}
                        {!address && (
                            <ConnectWalletSimple
                                setAddress={setAddress}
                            />
                        )}
                    </FarmerContainer>
                </div>
                <div className="md:place-self-start invisible md:visible ">
                    <Slide content={map1} contentIndex={mintNumber}>
                        {glassCardElement}
                    </Slide>
                </div>
            </div>
        );
    else return <></>;
};
