import React, { useState, } from "react";
import truncateEthAddress from "truncate-eth-address";
import { loginToMetaMask } from "../../utils/web3/hooks";
import metamaskLogo from "../../assets/images/metamask.svg.png";
import metafarmersLogo from "../../assets/images/logo-white.svg";

const SideBarIcon = ({
  icon,
  text: tooltip = "Please connect your wallet",
  onClick,
}) => {
  return (
    <div onClick={onClick} className="sidebar-icon group">
      {icon}
      <span class="sidebar-tooltip group-hover:scale-100">{tooltip}</span>
    </div>
  );
};
export const NavBar = ({ setAddress, address }) => {
  const [balance, setBalance] = useState(0);
  const [supply, setSupply] = useState();
  const [saleStatus, setSaleStatus] = useState();
  return (
    <div className="navfarmer top-0  h-14 md:h-20 ">
      <div className="px-2   navbar-start">
        <img alt={"logo"} src={metafarmersLogo} className="h-10" />
      </div>
      <div className="px-2 mx-2  navbar-center lg:flex">
        <div className="flex items-stretch">
          <a className="btn btn-ghost btn-sm rounded-btn">
            {process.env.NAVBAR_TITLE}
          </a>
        </div>
      </div>
      <div className="navbar-end">
        <div className="flex flex-col items-center">
          <div className="divider divider -m-1"/>
          <ConnectWalletBtnNavbar address={address} setAddress={setAddress} />
        </div>
      </div>
    </div>
  );
};

export const ConnectWalletBtnNavbar = ({ address, setAddress }) => {
  return (
    <>
      {!address && (
        <button
          onClick={() => {
            console.log("Connect with navbar");
            loginToMetaMask(setAddress);
          }}
          className="btn btn-wide invisible md:visible hover:shadow-black hover:shadow-xl"
        >
          Connect your wallet
          <img src={metamaskLogo} className="h-10" alt="metamask" />
        </button>
      )}
      {address && (
        <div className="badge badge-primary p-0  mr-1 t md:text-lg">{truncateEthAddress(address)}</div>
      )}
    </>
  );
};

export const ConnectWalletSimple = ({ setAddress }) => {
  const getWallet = () => {
    console.log("Connect with navbar");
    loginToMetaMask(setAddress);
  };
  return (
    <button
      onClick={getWallet}
      className="btn btn-wide hover:shadow-black mb-4 hover:shadow-xl"
    >
      Connect your wallet
      <img src={metamaskLogo} className="h-10" alt="metamask" />
    </button>
  );
};