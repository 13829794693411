import React, { useState } from "react";
import Footer from "./FarmerFooter";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'

const Layout = ({ children }) => {
  const { width, height } = useWindowSize()
  const {success,setSuccess} =useState(false)
  return (
    <div className="min-h-screen   bg-gradient-to-r from-tertiary via-fuchsia-900 to-primary p-4  md:p-0">
      <Confetti
      width={width}
      height={height}
      run={false}
    />
      {children}
      <Footer />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Layout;
