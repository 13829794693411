import React, {useState} from "react";
import tier1Img from "../../../assets/images/bigsmile.jpeg"
import tier2Img from "../../../assets/images/farmerBitcoin.png"
import {Mint} from "../../../utils/web3/hooks";

import whitelist from "../../../assets/oglist.json";

const WL = new Map();
whitelist.forEach((element) => {
    WL.set(element.address.toLowerCase(), element.proof);
});
export const MinterCard = ({mintNumber, add, minus, tier}) => {
    return (
        <section className="text-white body-font">
            <div className="container mx-auto flex px-5  items-center justify-center flex-col">
                <img
                    className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
                    alt="hero"
                    src={tier.image}
                />
                <div className="text-center flex flex-col items-center justify-center lg:w-2/3 ">
                    <h1 className="title-font text-white sm:text-4xl text-3xl mb-4 font-medium ">
                        {tier.bigTitle}
                    </h1>
                    <p className="mb-8 leading-relaxed">
                        {tier.text}

                    </p>
                    <MintAmount add={add} mintNumber={mintNumber} minus={minus}/>
                    <button

                        className="px-4 py-2  mt-4 mb-10 rounded-full bg-fuchsia-900 w-[18rem] font-medium tracking-wide bg-secondary text-white capitalize transition-colors duration-200 transform  rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80" onClick={async () => {
                        await Mint(
                            mintNumber,
                            WL,
                            process.env.MINT_PRICE,
                            process.env.CONTRACT_ADDRESS,
                        );
                    }}
                    >
                        Mint
                    </button>
                </div>


            </div>
        </section>
    );
};
const MintAmount = ({mintNumber, add, minus}) => {
    return (

        <div className="btn-group bg-white rounded-full  ">
            <button className="btn rounded-full bg-white text-black w-24 border-base-100 focus:bg-white text-xl"
                    onClick={minus}>-
            </button>
            <button className="btn rounded-full bg-white text-black w-24 focus:bg-white text-lg">{mintNumber}</button>
            <button className="btn rounded-full bg-white text-black w-24 focus:bg-white text-xl" onClick={add}>+</button>
        </div>)
}

const Public = [
    {
        image: tier1Img,
        bigTitle: "Silver Pick",
        title: "Whitelist presale ICO",
        text: "Welcome to the Meta-Farmers family! With your(s) NFT(s) you will now have direct access to our upcoming ICO.",
    },
    {
        image: tier2Img,
        title: "Whitelist presale ICO",
        title2: "Mining Stake",
        text: "Welcome to the Meta-Farmers Mine! You are now a Co-Owner of a wealthy mine. The mine will produce BTC with the best hardware available at the lowest prices. Have fun miners!",
    },
    {
        image: tier2Img,
        title: "Whitelist presale ICO",
        title2: "Mining Stake",
        text: "Welcome to the Meta-Farmers Mine! You are now a Co-Owner of a wealthy mine. The mine will produce BTC with the best hardware available at the lowest prices. Have fun miners!",
    }
]

export const MerakiCards = ({mintNumber, add, minus, tier}) => {
    return (
        <div className="max-w-2xl mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="p-6">
                <div>
          <span className="text-xs font-medium text-blue-600 uppercase dark:text-blue-400">
            {tier.title}
              {tier.title2}
          </span>
                    <a
                        href="#"
                        className="block mt-2 text-2xl font-semibold text-gray-800 transition-colors duration-200 transform dark:text-white hover:text-gray-600 hover:underline"
                    >
                        {tier.bigTitle}
                    </a>
                    <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                        {tier.text}
                    </p>
                </div>

                <div className="mt-4">
                    <div className="flex items-center justify-center gap-6 ">
                        <button
                            onClick={minus}
                            className="px-4 py-2 font-medium tracking-wide bg-secondary text-white capitalize transition-colors duration-200 transform  rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                        >
                            -
                        </button>
                        <div className="badge badge-lg">{mintNumber}</div>
                        <button
                            onClick={add}
                            className="px-4 py-2 font-medium tracking-wide text-white bg-secondary capitalize transition-colors duration-200 transform  rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                        >
                            +
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
