import MintCard from "../MintIncrement";
import FarmerButton, {FarmerButtonNoWL} from "../FarmerButton";
import {Mint} from "../../../utils/web3/hooks";
import React from "react";
import whitelist from "../../../assets/oglist.json";

const WL = new Map();
whitelist.forEach((element) => {
    WL.set(element.address.toLowerCase(), element.proof);
});
export const MintChoiceDesktop = ({
                               setMintNumber,
                               mintNumber,
                               isWhitelist,
                               add,
                               minus,
                           }) => {
    return (
        <div className="md:flex md:flex-row flew-col items-center ">
            <MintCard
                add={add}
                minus={minus}
                setMintNumber={setMintNumber}
                mintNumber={mintNumber}
            />
            {isWhitelist ? (
                <FarmerButton
                    actionClick={async () => {
                        await Mint(
                            mintNumber,
                            WL,
                            process.env.MINT_PRICE,
                            process.env.CONTRACT_ADDRESS,
                        );
                    }}
                    mintNumber={mintNumber}
                />
            ) : (
                <FarmerButtonNoWL />
            )}

            {isWhitelist && (
                <div className="badge badge-lg ml-2 xl:ml-4 mt-4 text-sm">
                    {(mintNumber * process.env.MINT_PRICE).toFixed(2) + " eth"}
                </div>
            )}
        </div>
    );
};
