import React, { useEffect, useState } from "react";
import {Minter } from "../components/design/Minter";
import whitelist from "../assets/oglist.json";
import Layout from "../components/design/layout";
import { NavBar } from "../components/design/NavBar";
import detectEthereumProvider from "@metamask/detect-provider";

const WL = new Map();
whitelist.forEach((element) => {
  WL.set(element.address.toLowerCase(), element.proof);
});
const IndexPage = (e) => {
  const [address, setAddress] = useState();
  const [isWhitelist, setIsWhiteList] = useState(false);
  useEffect(() => {
    setIsWhiteList(WL.has(address));
  }, [address]);
  console.log("whitelist ?", WL.has(address));
  useEffect(async () => {
    const provider = await detectEthereumProvider();
    if (provider) setAddress(window.ethereum.selectedAddress);
    else {

      window.location.replace(
          "https://metamask.app.link/dapp/mint.meta-farmers.com/"
      );
    }
  }, []);

  return (
    <Layout>
        <NavBar address={address} setAddress={setAddress} />
      <Minter
        address={address}
        setAddress={setAddress}
        isWhitelist={isWhitelist}
      />
    </Layout>
  );
};
export default IndexPage;
