import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const changeSelect = (number) => {
  if ( Number < 5) return 0;
  if ((number = 5)) return 1;
};
const ogText = {
  "Silver Pick": [
    {
      id: 1,
      title: "Whitelist presale ICO",
      text: "Welcome to the Meta-Farmers family! With your(s) NFT(s) you will now have direct access to our upcoming ICO.",
    },
  ],
  "Gold Pick": [
    {
      id: 1,
      title: "Whitelist presale ICO",
      title2: "Mining Stake",
      text: "Welcome to the Meta-Farmers Mine! You are now a Co-Owner of a wealthy mine. The mine will produce BTC with the best hardware available at the lowest prices. Have fun miners!",
    },
  ],
};

const privateText = {
  "Silver Pick": [
    {
      id: 1,
      title: "Whitelist presale ICO",
      text: "You are at 1 NFT of getting access at the whitelist of our coming ICO",
    },
  ],
  "Gold Pick": [
    {
      id: 1,
      title: "Whitelist presale ICO",
      title2: "Mining Stake",
      text: "You are at 3 NFT to get access to our metamining pool with  21.25 TH/s",
    },
  ],
  "Platinium Pick": [
    {
      id: 1,
      title: "Godlike",
      text: "You are at 3 NFT to get access to our metamining pool with  21.25 TH/s",
    },
  ],
};

const TiersTab = ({
  setChangeTab,
  tabToSelect: mintNumber,
  elements,
  setMint,
  mint,
}) => {
  const [tiersTabIndex,setTiersTabIndex]=useState(1)
  let [categories] = useState(ogText);
  useEffect(() => {
    if (mint < 5) {
      setChangeTab(0);
      setTiersTabIndex(0)
    }
    if (mint == 5) {
      setChangeTab(1);
      setTiersTabIndex(1)
    }
  }, [mint]);

  return (
    <div className="w-full max-w-md px-2 py-16 sm:px-0">
      <Tab.Group
      selectedIndex={tiersTabIndex}
        onChange={(index) => {
          setChangeTab(elements.get(index + 1));
          if (index == 1) setMint(5);
          if (index == 0) setMint(3);
        }}
      >
        <Tab.List className="flex p-1 space-x-1 bg-blue-900/20 rounded-xl">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              manual
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm leading-5 font-medium text-blue-200 rounded-lg",
                  "focus:outline-none focus:ring-2  ring-offset-blue-400  ring-opacity-60",
                  selected
                    ? "bg-gradient-to-r  from-gradien1 via-gradien2 to-gradien3 shadow"
                    : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((posts, idx) => (
            <Tab.Panel
              key={idx}
              className={classNames(
                " rounded-xl p-3",
                "focus:outline-none focus:ring-2 ring-offset-2  ring-white ring-opacity-60"
              )}
            >
              <ul>
                {posts.map((post) => (
                  <li
                    key={post.id}
                    className="relative p-3 rounded-md hover:bg-coolGray-100"
                  >
                    <h1 className="text-lg font-medium leading-5">
                      <div class="badge badge-primary">{post.title}</div>
                      {post.title2 && (
                        <div class="badge badge-secondary">{post.title2}</div>
                      )}
                    </h1>
                    <span> {post.text}</span>

                    <a
                      href="#"
                      className={classNames(
                        "absolute inset-0 rounded-md",
                        "focus:z-10 focus:outline-none focus:ring-2 ring-blue-400"
                      )}
                    />
                  </li>
                ))}
              </ul>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
export default TiersTab;
