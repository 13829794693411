import { ethers } from "ethers";
import RaffleAbi from "../../assets/abi/JeanCode.json";
import { toast } from "react-toastify";
const contractAddress = process.env.CONTRACT_ADDRESS;
const toastParameters = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export async function Mint(amount, WL, mintPrice) {
  let response;
  //const mintWorkflow=workflowToString(workflowStatus())
  const mintWorkflow = "Private";
  //if (mintWorkflow==="ogSales") {response=await mintOg(amount,WL,mintPrice)}
  if (mintWorkflow === "Private") {
    response = await mintPrivate(amount, WL, mintPrice);
  }
  console.log(response);
  return response;
}

function getContract() {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    console.log(" signer :", signer);
    const contract = new ethers.Contract(
      contractAddress,
      RaffleAbi.abi,
      signer
    );
    contract.connect(provider);

    return contract;
  } catch (error) {
    toast("Please install Metamask", toastParameters);
    throw ("Cant find Metamask error: ", error);
  }
}

export function isWhitheListed(address) {
  console.log("signer is ", address);
  console.log("contract is ", contractAddress);
  console.log(
    "signer is whitelisted :"
    //WL.has(address)
  );
  return true;
}

export async function mintOg(amount, WL, mintPrice) {
  if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
    const contract = getContract();
    console.log("Contract Mint : ", contract);
    try {
      window.ethereum.on("error", (message, code) =>
        console.log(" Error on metamask: ", code, message)
      );
      const data = await contract.redeemRaffle(
        amount,
        WL.get(window.ethereum.selectedAddress),
        {
          value: ethers.utils.parseEther((mintPrice * amount).toString()),
        }
      );
      console.log("contract data: ", data);
    } catch (error) {
      const message = metamaskErrorHandler(error);
      toast(message, toastParameters);
      console.log(error);
    }
  }
}

export async function mintPrivate(amount, WL, mintPrice) {
  if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
    const contract = getContract();
    console.log("Contract Mint : ", contract);
    try {
      window.ethereum.on("error", (message, code) =>
        console.log(" Error on metamask: ", code, message)
      );
      const data = await contract.redeemPrivateSale(
        amount,
        WL.get(window.ethereum.selectedAddress),
        {
          value: ethers.utils.parseEther((mintPrice * amount).toString()),
        }
      );
      console.log("contract data: ", data);
      console.log("PRICE ", await contract.WHITELIST_PRICE());
    } catch (error) {
      const message = metamaskErrorHandler(error);
      toast(message, toastParameters);
      console.log(error);
    }
  }
}

async function totalSupply() {
  if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      contractAddress,
      RaffleAbi.abi,
      provider.getSigner()
    );
    contract.connect(provider);
    try {
      const data = await contract.totalSupply();
      const supply = parseInt(data._hex, 16);
      console.log("data: ", supply);
      return supply;
    } catch (err) {
      console.log("Error: ", err);
    }
  }
}

async function workflowStatus() {
  if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      contractAddress,
      RaffleAbi.abi,
      provider.getSigner()
    );
    contract.connect(provider);
    try {
      const data = await contract.getWorkflowStatus();
      const supply = parseInt(data._hex, 16);
      console.log("data: ", supply);
      return supply;
    } catch (err) {
      console.log("Error: ", err);
    }
  }
}

export const loginToMetaMask = (setAddress) => {
  try {
    const { ethereum } = window;
    if (!ethereum) {
      toast(" Please install Metamask");
      window.location.replace(
        "https://metamask.app.link/dapp/mint.meta-farmers.com/"
      );
    }
    ethereum.on("accountsChanged", function (accounts) {
      setAddress(window.ethereum.selectedAddress);
      const message = "new address " + window.ethereum.selectedAddress;
      toast(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });

    window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then((addressl) => {
        setAddress(window.ethereum.selectedAddress);
        console.log("Metamask is connected");
      });
  } catch (error) {
    if (error.code === 4001) {
      // EIP-1193 userRejectedRequest error
      console.log("Please connect to MetaMask.");
      const message = "Please connect to MetaMask.";
      toast(message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      console.error(error);
      throw error;
    }
  }
};

export const chainIdtoName = (chainId) => {
  switch (chainId) {
    case 1:
      return "Mainnet";
    case 3:
      return "Ropsten";
    case 4:
      return "Rinkeby";
    case 42:
      return "Kovan";
    case 5:
      return "Goerli";
    default:
      return "unknown";
  }
};

function workflowToString(status) {
  switch (status) {
    case 1:
      return "ogSales";
    case 2:
      return "ogPrivateSales";
    case 3:
      return "Private";
    case 4:
      return "Public";
    default:
      break;
  }
  throw "Cant Get Status";
}

function metamaskErrorHandler(error) {
  let message;
  if (error.code === 4001) {
    message = " You have rejected the transaction";
    return message;
  }
  console.log("", error);
  if (typeof error.error != undefined) {
    if (error.error.code) {
      if (error.error.code === -32000) {
        console.log("insufficient funds");
        message = "insufficient funds";
        return message;
      }
      if (error.error.code === -32603) {
        if (
          error.error.message === "execution reverted: Raffle sale has ended"
        ) {
          message = "Raffle sale has ended";
          return message;
        }
        if (
          error.error.message === "execution reverted: Meta-Farmers: Insuficient funds"
        ) {
          message = "Insuficient funds";
          return message;
        }
        message = "You have minted too much token";
        return message;
      }
    }
  }
  return error;
}
