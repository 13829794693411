import React, { useEffect, useState } from "react";
import {
  BrowserView,
  MobileView,
} from "react-device-detect";
import {  MinterCard } from "./mobile/MinterMobile";
import tier1Img from "../../assets/images/bigsmile.jpeg";
import tier2Img from "../../assets/images/farmerBitcoin.png";
import {MinterDesktop} from "./desktop/MinterDesktop";

const handleTier = (mintNumber,setTier) => {
  console.log(mintNumber)
  if (mintNumber>=4)setTier(Private[1])
  if (mintNumber<5)setTier(Private[0])
}

export const Minter = ({ address, setAddress, isWhitelist }) => {
  const [mintNumber, setMintNumber] = useState(1);
  const [tier, setTier] = useState(Private[0]);
  const add = () => {
    if (mintNumber < 5) setMintNumber(mintNumber + 1);
    handleTier(mintNumber+1,setTier)
  };
  const minus = () => {
    if (mintNumber > 1) setMintNumber(mintNumber - 1);
    handleTier(mintNumber-1,setTier)
  };
  if (process.env.PAUSED === "false") {
  return (
    <div>
      <BrowserView>
        <MinterDesktop
          address={address}
          setAddress={setAddress}
          isWhitelist={isWhitelist}
          mintNumber={mintNumber}
          add={add}
          minus={minus}
        />
      </BrowserView>
      <MobileView>
        <MinterMobile
          address={address}
          setAddress={setAddress}
          isWhitelist={isWhitelist}
          mintNumber={mintNumber}
          add={add}
          minus={minus}
          tier={tier}
        />
      </MobileView>
    </div>
  );}
  else return <></>
};

const MinterMobile = ({ add, minus, mintNumber,tier }) => {
  return <MinterCard minus={minus} mintNumber={mintNumber}  tier={tier} add={add} />;
};
const Private = [
  {
    image :tier1Img,
    bigTitle: "Silver Pick",
    title: "Whitelist presale ICO",
    text: "Welcome to the Meta-Farmers family! With your(s) NFT(s) you will now have direct access to our upcoming ICO.",
  },
  {
    image:tier2Img,
    bigTitle: "Gold Pick",
    title: "Whitelist presale ICO",
    title2: "Mining Stake",
    text: "Welcome to the Meta-Farmers Mine! You are now a Co-Owner of a wealthy mine. The mine will produce BTC with the best hardware available at the lowest prices. Have fun miners!",
  },
  {
    image:tier2Img,
    title: "Whitelist presale ICO",
    title2: "Mining Stake",
    text: "Welcome to the Meta-Farmers Mine! You are now a Co-Owner of a wealthy mine. The mine will produce BTC with the best hardware available at the lowest prices. Have fun miners!",
  }
]
const Public = [
  {
    image :tier1Img,
    bigTitle: "Silver Pick",
    title: "Whitelist presale ICO",
    text: "Welcome to the Meta-Farmers family! With your(s) NFT(s) you will now have direct access to our upcoming ICO.",
  },
  {
    image:tier2Img,
    title: "Whitelist presale ICO",
    title2: "Mining Stake",
    text: "Welcome to the Meta-Farmers Mine! You are now a Co-Owner of a wealthy mine. The mine will produce BTC with the best hardware available at the lowest prices. Have fun miners!",
  },
  {
    image:tier2Img,
    title: "Whitelist presale ICO",
    title2: "Mining Stake",
    text: "Welcome to the Meta-Farmers Mine! You are now a Co-Owner of a wealthy mine. The mine will produce BTC with the best hardware available at the lowest prices. Have fun miners!",
  }
]