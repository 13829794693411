import React from "react";
import tier1Img from "../../assets/images/captainminer.png"

 export const GlassCard =({title="Titre",cardImage=tier1Img}) =>{
   return (
     <>
    <div className="card glass lg:card-compact h-fit ml-6 w-96 text-neutral-content hover:translate-y-10 hover:bg-secondary transition-all duration-500  shadow-black shadow-2xl">
      <figure className="-p-3">
        <img src={cardImage} className=" shadow-white shadow-2xl"/>
      </figure>
      <div className=" card-body">
        <h1 className="card-title text-2xl text-center">{title}</h1>
      </div>
    </div>
     </>
   )
 }
export default GlassCard;
