import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
const req =`
    query {
      logo: file(relativePath: { eq: "logoWhite.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
          )
        }
      }
    }
  `
export default function Footer() {
  const img = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logoWhite.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 100
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
          )
        }
      }
    }
  `);
  return (
    <div className={" relative md:fixed  max-h-[6rem] inset-x-0 bottom-0 bg-primary"}>
      <div className={"flex justify-center m-2"}>
        <GatsbyImage image={img.logo.childImageSharp.gatsbyImageData} />
      </div>
    </div>
  );
}